import { api } from '.';

export function getCollectOrder(payload) {
  return api('/v1/collectOrders/getCollectOrder', payload);
}

export function getCollectOrders(payload) {
  payload = { page: 1, limit: 20, ...payload };
  return api('/v1/collectOrders/getCollectOrders', payload);
}

export function replenishOrder(payload) {
  return api('/v1/collectOrders/replenishOrder', payload);
}

export function sendNotify(payload) {
  return api('/v1/collectOrders/sendNotify', payload);
}

export function placeOrderByMerchant(payload) {
  return api('/v1/collectOrders/placeOrderByMerchant', payload);
}

export function mockOrder(payload) {
  return api('/v1/collectOrders/mockOrder', payload);
}

export function getNotProcessedCollectOrders(payload) {
  return api('/v1/collectOrders/getNotProcessedCollectOrders', payload);
}

// without auth
export function getCollectOrderForPay(payload) {
  return api('/v1/collectOrders/getCollectOrderForPay', payload);
}

export function approveReplenishOrder(payload) {
  return api('/v1/collectOrders/approveReplenishOrder', payload);
}

export function rejectReplenishOrder(payload) {
  return api('/v1/collectOrders/rejectReplenishOrder', payload);
}
