export default [
  {
    color: 'Error200',
    text: '未支付',
    value: 'unpaid'
  },
  {
    color: 'Alert200',
    text: '已支付',
    value: 'paid'
  },
  {
    color: 'Alert300',
    text: '付款中',
    value: 'paying'
  },
  {
    color: 'Alert500',
    text: '待審核',
    value: 'pending_approval'
  },
  {
    color: 'Error400',
    text: '未返回',
    value: 'no_return'
  },
  {
    color: 'Error400',
    text: '超時支付',
    value: 'expired'
  },
  {
    color: 'Success400',
    text: '已完成',
    value: 'completed'
  }
];
