<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card min-height="500">
      <v-card-title class="pos-r">
        審核補單
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <SizeBox height="48" />
        <v-form ref="form">
          <v-text-field
            v-if="settings.enabled_collect_order_replenish_otp"
            rounded
            label="Google 驗證碼"
            v-model="otpCode"
            :rules="[$rules.required]"
            outlined
          ></v-text-field>
          <SizeBox height="12" />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          rounded
          color="Secondary100"
          depressed
          large
          @click="close"
          :disabled="loading"
        >
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="error"
          dark
          depressed
          large
          :loading="loading"
          @click="reject"
        >
          駁回
        </v-btn>
        <v-btn
          rounded
          color="success"
          dark
          depressed
          large
          :loading="loading"
          @click="approve"
        >
          通過
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  approveReplenishOrder,
  rejectReplenishOrder
} from '@/api/collectOrders';
import { mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({
        /**
         * mode: String, // new, edit
         */
      })
    }
  },

  data() {
    return {
      loading: false,
      otpCode: ''
    };
  },

  computed: {
    ...mapGetters({
      settings: 'app/getSiteSetting'
    })
  },

  watch: {
    value(v) {
      v ? this.init() : this.reset();
    }
  },

  methods: {
    init() {
      //
    },
    reset() {
      //
    },
    close() {
      this.$emit('close');
    },
    async approve() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;

      const { error } = await approveReplenishOrder({
        id: this.passData.id,
        otp_code: this.otpCode
      });

      if (!error) {
        this.$toast.success('審核成功');
        this.$emit('save');
        this.close();
      }

      this.loading = false;
    },
    async reject() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;

      const { error } = await rejectReplenishOrder({
        id: this.passData.id,
        otp_code: this.otpCode
      });

      if (!error) {
        this.$toast.success('駁回成功');
        this.$emit('save');
        this.close();
      }

      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
