<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card min-height="500">
      <v-card-title class="pos-r">
        補單
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <SizeBox height="48" />
        <p>訂單金額: {{ passData && passData.amt }}</p>
        <v-form ref="form" @submit.prevent="submit">
          <v-text-field
            rounded
            label="補單金額"
            v-model.number="form.replenishment_amt"
            type="number"
            :rules="[$rules.required]"
            outlined
            class="remove-append-margin"
          >
            <template #append>
              <v-btn
                rounded
                depressed
                class="ma-0 m-w100p"
                @click="form.replenishment_amt = Number(passData.verify_amt)"
              >
                帶入訂單金額
              </v-btn>
            </template>
          </v-text-field>

          <v-card class="mb-7">
            <v-card-text>選擇所屬通道</v-card-text>
            <v-card-text>
              <v-select
                rounded
                label="通道類型"
                v-model="channelType"
                :items="channelTypeOptions"
                :loading="isFetching"
                clearable
                outlined
              ></v-select>

              <v-expand-transition>
                <c-autocomplete
                  v-if="channelType"
                  rounded
                  label="所屬通道"
                  v-model="form.collect_channel_id"
                  :api="getCollectChannels"
                  outlined
                ></c-autocomplete>
              </v-expand-transition>

              <v-expand-transition>
                <c-autocomplete
                  v-if="form.collect_channel_id"
                  rounded
                  label="子帳戶"
                  v-model="form.card_id"
                  text-name="name_1"
                  :api="getCards"
                  :limit="1000"
                  outlined
                ></c-autocomplete>
              </v-expand-transition>
            </v-card-text>
          </v-card>

          <v-text-field
            v-if="settings.enabled_collect_order_replenish_otp"
            rounded
            label="谷歌驗證碼"
            v-model="form.otp_code"
            :rules="[$rules.required]"
            outlined
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          rounded
          color="Secondary100"
          depressed
          large
          :disabled="loading"
          @click="close"
        >
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="primary"
          dark
          depressed
          large
          :loading="loading"
          @click="submit"
        >
          補單
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { replenishOrder } from '@/api/collectOrders';
import {
  getCollectChannels,
  getCollectChannelTypes
} from '@/api/collectChannels';
import { getCards } from '@/api/cards';
import { mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({
        /**
         * mode: String, // new, edit
         */
      })
    }
  },

  data() {
    return {
      loading: false,
      isFetching: false,
      channelType: '',
      channelTypeOptions: [],
      form: {
        replenishment_amt: 0,
        otp_code: '',
        collect_channel_id: null, // optional
        card_id: null // optional
      }
    };
  },

  computed: {
    ...mapGetters({
      settings: 'app/getSiteSetting'
    })
  },

  watch: {
    value(v) {
      v ? this.init() : this.reset();
    },
    channelType(v) {
      this.form.collect_channel_id = null;
    },
    'form.collect_channel_id': {
      handler() {
        this.form.card_id = null;
      }
    }
  },

  methods: {
    init() {
      this.fetchData();
    },
    reset() {
      this.loading = false;
      const originData = this.$options.data();
      Object.keys(originData).forEach((key) => {
        this.$set(this.$data, key, originData[key]);
      });
      this.$refs.form.resetValidation();
    },
    close() {
      this.$emit('close');
    },
    async fetchData() {
      this.isFetching = true;

      const Type = await getCollectChannelTypes();
      if (!Type.error) {
        this.channelTypeOptions = Type.map(({ id, name, code }) => ({
          text: name,
          value: id,
          code
        }));

        if (this.channelTypeOptions.length) {
          this.channelType = this.channelTypeOptions[0].value;
        }

        this.$nextTick(() => {
          this.setInfo();
        });
      }

      this.isFetching = false;
    },
    setInfo() {
      if (this.passData.collect_channel && this.passData.collect_channel.id) {
        this.form.collect_channel_id = this.passData.collect_channel.id;
      }
      this.$nextTick(() => {
        if (this.passData.card && this.passData.card.id) {
          this.form.card_id = this.passData.card.id;
        }
      });
    },
    async getCollectChannels(payload) {
      return await getCollectChannels({
        collect_channel_type_id: this.channelType,
        ...payload
      });
    },
    async getCards(payload) {
      return await getCards({
        collect_channel_id: this.form.collect_channel_id,
        ...payload
      });
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;

      const { error } = await replenishOrder({
        id: this.passData.id,
        ...this.form
      });

      if (!error) {
        this.$toast('成功');
        this.close();
        this.$emit('save');
      } else {
        this.$toast.error('操作失敗');
      }

      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.remove-append-margin {
  ::v-deep.v-input__append-inner {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
</style>